import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '././services/AuthGuardService';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'edi-search',
    pathMatch: 'full'
  },
  {
    path: 'edi-search',
    loadChildren: () => import('../app/manufacturer/manufacturer.module').then(m => m.ManufacturerModule),
    canActivate: [AuthGuardService] 
  },
  {
    path: 'configuration',
    loadChildren: () => import('../app/configuration-screen/configuration-screen.module').then(m => m.ConfigurationScreenModule),
    canActivate: [AuthGuardService],
    data : {
      // TODO : Try if we can make this dynamic
      role: ['OT_GLOBAL_ADMIN', 'COMMUNITY_TSD']
    } 
  },
  {
    path: 'userPreference',
    loadChildren: () => import('../app/user-preference/user-preference.module').then(m => m.UserPreferenceModule),
    canActivate: [AuthGuardService] 
  },
  {
    path: 'admin',
    loadChildren: () => import('../app/admin/admin-screen.module').then(m => m.AdminScreenModule),
    canActivate: [AuthGuardService],
    data : {
      role: ['OT_GLOBAL_ADMIN', 'COMMUNITY_ADMIN','COMMUNITY_USER']
    }  
  },
  {
    path: 'exceptions',
    loadChildren: () => import('../app/exceptions/exception-screen.module').then(m => m.ExceptionScreenModule),
    canActivate: [AuthGuardService],
    data : {
      role: ['OT_GLOBAL_ADMIN']
    }  
  },
  {
    path: 'Return to Portal',
    // loadChildren: () => import('../app/logout/logout.module').then(m => m.LogoutModule)
    loadChildren: () => new Promise( () => {
      sessionStorage.clear();
      window.location.replace(environment.supplyPowerURL);
    })
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
