import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuardService } from '../services/AuthGuardService';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../information/information.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  menus : Array<Object> = []
  shortName : string = '';
  constructor(private _router: Router, private authGuardService : AuthGuardService , public dialog: MatDialog) {
    authGuardService.getMenu.subscribe(menuList => this.updateMenu(menuList));
  }

  ngOnInit(): void {
    let userDetails = sessionStorage.getItem("userDetails");
    if(userDetails) {
      userDetails = JSON.parse(userDetails);
      const role = userDetails["roleName"];
      const username = userDetails["firstName"] +' '+ userDetails["lastName"];
      const communityName = userDetails["communityName"];
      this.menus.push({name : username}, {name : 'Search Messages', path : '/edi-search'}, {name : 'User Preference', path : '/userPreference'});
      if(role === "OT_GLOBAL_ADMIN" || role === "COMMUNITY_ADMIN" || (role === "COMMUNITY_USER" && communityName === environment.communityName )) {
        this.menus.push({name : 'Administration', path : '/admin'});
      }
      if(role === "OT_GLOBAL_ADMIN" || role === "COMMUNITY_TSD") {
        this.menus.push({name : 'Configuration', path : '/configuration'});
      }
      if(role === "OT_GLOBAL_ADMIN") {
        this.menus.push({name : 'Exceptions', path : '/exceptions'});
      }
      this.menus.push({name : 'Return to Portal', path : '/Return to Portal'});
      const names = username.split(" ");
      this.shortName = names[0].substring(0,1) + names[1].substring(0,1);
    } else {
      this.menus.push({name : "User name"}, {name : 'Search Messages', path : '/edi-search'}, {name : 'User Preference', path : '/userPreference'},{name : 'Return to Portal', path : '/Return to Portal'}); 
    }
  }

  updateMenu = (menuList) => {
    const username = menuList[0].name;
    const names = username.split(" ");
    this.shortName = names[0].substring(0,1) + names[1].substring(0,1);
    this.menus = menuList;
  }

  navigateTo = (path) => {
    this._router.navigate([path]);
  }

  redirectTo() {
  sessionStorage.clear();
  window.location.replace(environment.supplyPowerURL);
 }
 aboutEDISearch() {
  this.dialog.open(InformationComponent , {
    disableClose: true,
    width: '70em',
    minHeight: 'calc(100vh - 220px)'
  });
}
     
}
