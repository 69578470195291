import { EventEmitter, Injectable, Output } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '../services/api.service';
import { LoaderService } from '../loader/loader.service';
import { popup } from '../config/pop-up-content.json';
import { NotificationService } from '../services/notification.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class AuthGuardService implements CanActivate {
  username : string = ""; 
  iamUID : string = "";
  @Output() getMenu = new EventEmitter();
  menus : Array<Object> = []
  constructor(private apiService: ApiService, private router: Router, private loadingService: LoaderService, private notifyService: NotificationService) {

  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isUserLoggedIn = sessionStorage.getItem("isUserLoggedIn");
    if(!isUserLoggedIn) {
       this.loadingService.openLoadingIndicator();
        const idTokenPayload = {
            "idToken" : ""
        };
        this.apiService.validateIDToken(idTokenPayload).subscribe(response => {
          if(response.status === 200 && response.statusText === 'OK') {
            const userDetails = response.body;
            sessionStorage.setItem("userDetails",  JSON.stringify(userDetails));
            sessionStorage.setItem("isUserLoggedIn", "true");
            this.loadingService.closeLoadingIndicator();
            this.updateMenuList(userDetails["roleName"], userDetails["firstName"], userDetails["lastName"],userDetails["communityName"]);
            this.getMenu.emit(this.menus);
            this.loadingService.closeLoadingIndicator();
            this.router.navigate(['']);
          } else {
            this.loadingService.closeLoadingIndicator();
          } 
        }, error => {                    
          if(error.error.code === "400143" || error.error.code === "400142"){
              this.notifyService.showNotification(popup.userAuthenticationError, 'error',1800000);
            } else if(error.error.code === "400144"){              
              window.location.href = `${environment.redirectURL}`;
            } else {
              this.notifyService.showNotification(popup.errorNotification, 'error',1800000);
            }
            this.loadingService.closeLoadingIndicator();
        });
    } else {
        const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
        if(route.data.role && route.data.role.indexOf(userDetails.roleName) === -1) {
          this.router.navigate(['']);
          return false;         
        }
        return true;
    }
    return false;
  }

  updateMenuList = (role, firstname, lastname, communityName) => {
    const username = firstname + ' ' + lastname;
    this.menus.push({name : username}, {name : 'Search Messages', path : '/edi-search'}, {name : 'User Preference', path : '/userPreference'});
    if(role === "OT_GLOBAL_ADMIN" || role === "COMMUNITY_ADMIN" || (role === "COMMUNITY_USER" && communityName === environment.communityName )) {
      this.menus.push({name : 'Administration', path : '/admin'});
    }
    if(role === "OT_GLOBAL_ADMIN" || role === "COMMUNITY_TSD") {
      this.menus.push({name : 'Configuration', path : '/configuration'});
    }
    if(role === "OT_GLOBAL_ADMIN") {
      this.menus.push({name : 'Exceptions', path : '/exceptions'});
     }
    this.menus.push({name : 'Return to Portal', path : '/Return to Portal'});
  }
}