<div *ngIf="shortName !== ''" class="top-header">
    <div class="container">
        <div class="mid-top">
            <div class="logo-wrap">
                <div class="logo">
                    <img src="assets/img/logo.svg">
                </div>
                <div class="logo-title">
                    <p> &nbsp;| EDI Search</p>
                </div>
            </div>
            <div class="right-top">
                <div class="right-top-links">
                    <ul class="right-links">
                        <li><a (click)="aboutEDISearch()"><img src="assets/icons/about.svg"></a></li>
                        <li><a (click)="redirectTo()">Return to Portal</a></li>
                    </ul>
                </div>
                <div class="user-profile">
                    <mat-menu #userMenu="matMenu">
                        <div *ngFor="let menu of menus">
                            <a class="mat-menu-item" (click)="menu.path ? navigateTo(menu.path) : false">{{menu.name}}</a>
                        </div>
                    </mat-menu>
                    <button [matMenuTriggerFor]="userMenu"  class="user">{{shortName}}</button>
                </div>
            </div>
        </div>
    </div>
</div>